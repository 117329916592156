
interface UserRatingType {
  rating_type_id: string | number;
  rating: string | number;
}

import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentorcastRatings",
  data() {
    return {
      data: {
        rating: "",
        comment: "",
        badge_id: "",
        user: [],
        is_anonymous: false
      },
      maxRating: 10,
      mentorcastRating: 0,
      maxMentorEvaluation: 10,
      maxCommentChars: 150,
      ratingBtnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      mentorcast: "mentorcast/getMentorcast",
      badges: "helper/getBadges",
      ratingTypes: "rating/getRatingTypes"
    })
  },
  mounted(): void {
    this.$store
      .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
      .then(data => {
        this.$store.dispatch("helper/GET_BADGES", {
          type: data.user.type,
          badge_type: data.user.badge_type
        });
      });
    this.$store.dispatch("rating/GET_RATING_TYPES", {
      type: "mentor"
    });
  },
  methods: {
    // Set mentorcast rating
    setMentorcastRating(index: number) {
      this.data.rating = (index + 1).toString();
    },
    // Set mentor badge
    setMentorBadge(id: string) {
      this.data.badge_id = id;
    },
    onRating() {
      this.ratingBtnLoading = true;

      this.$store
        .dispatch("mentorcast/GIVE_RATING", {
          id: this.$route.params.id,
          data: this.data
        })
        .then(() => {
          this.ratingBtnLoading = false;
          this.$router.push(`/mentorcasts/${this.$route.params.id}`);
          (this as any).$alertify.success(
            (this as any).$t("mentorcastHero.mentorcastRatingSuccessMessage")
          );
        })
        .catch(error => {
          this.ratingBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    setUserRating(item: any, rating: string) {
      const index = this.data.user.findIndex(
        (u: UserRatingType) => u.rating_type_id == item.id
      );
      if (index > -1) {
        //@ts-ignore
        this.data.user[index].rating = rating;
        return;
      }
      this.data.user.push({
        //@ts-ignore
        rating_type_id: item.id,
        //@ts-ignore
        rating
      });
    },
    getUserRating(item: any) {
      const index = this.data.user.findIndex(
        (u: any) => u.rating_type_id == item.id
      );
      if (index > -1) {
        //@ts-ignore
        return this.data.user[index].rating;
      }
      return 0;
    }
  }
});
